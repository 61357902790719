/*###########################################################################
// Forms
//#########################################################################*/

const forms = {
	config: {

	},

	elements: {},

	init() {
		const self = this

	}
}

window.addEventListener('page-change', function () {
  forms.init()
})

window.addEventListener('load', function () {
	forms.init()
})
