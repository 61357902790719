/*###########################################################################
// Accordions
//#########################################################################*/

import { debounce } from '../utilities/_throttleDebounce'

const accordions = {
	config: {
		accordion: '.accordion, .poAccordion',
		accordionHeader: '.accordion__header, .poAccordion__header',
		accordionContent: '.accordion__content, .poAccordion__content'
	},

	elements: {
		accordions: null
	},

	init() {
		const self = this

		self.elements.accordions = document.querySelectorAll(self.config.accordion)

		if (self.elements.accordions !== null) {
			for (let i = 0; i < self.elements.accordions.length; i++) {
				const accordion = self.elements.accordions[i]

				self.handleAccordion(accordion)
			}
		}
	},

	handleAccordion(accordion) {
		const self = this

		const accordionHeader = accordion.querySelector(self.config.accordionHeader)
		const accordionContent = accordion.querySelector(self.config.accordionContent)

		let accordionContentHeight = accordionContent.scrollHeight
		accordion.setAttribute('data-content-height', accordionContentHeight)

		accordionHeader.addEventListener('click', function () {
			// Check for current state and set accordingly
			//
			if (accordion.classList.contains('is-active')) {
				accordion.classList.remove('is-active')
				accordionContent.style.height = '0px'
			} else {
				accordion.classList.add('is-active')
				accordionContent.style.height = accordion.getAttribute('data-content-height') + 'px'
			}

			// Trigger an event so other components can listen for height changes
			//
			let accordionEvent = new Event('accordion-open')
			window.dispatchEvent(accordionEvent)
		})

		if (accordion.classList.contains('is-active')) {
			accordionContent.style.height = accordion.getAttribute('data-content-height') + 'px'
		}

		window.addEventListener('resize', debounce(function () {
			accordionContent.style.height = ''
			accordion.setAttribute('data-content-height', 0)

			accordionContentHeight = accordionContent.scrollHeight
			accordion.setAttribute('data-content-height', accordionContentHeight)

			if (accordion.classList.contains('is-active')) {
				accordionContent.style.height = accordionContent.scrollHeight + 'px'
				accordion.setAttribute('data-content-height', accordionContentHeight)
			}
		}, 500))
	}
}

window.addEventListener('page-change', function () {
	setTimeout(function () {
		accordions.init()
	}, 100)
})

window.addEventListener('load', function () {
	setTimeout(function () {
		accordions.init()
	}, 250)
})
