/*###########################################################################
// Hubspot gated
//#########################################################################*/

const hubspotGated = {
  config: {
    hubspotForm: '.hubspotGated'
  },

  elements: {},

  init: function () {
    var self = this;

    self.elements.hubspotForm = document.querySelector(self.config.hubspotForm)

    if (self.elements.hubspotForm !== null) {
			self.handleHubspotForm(self.elements.hubspotForm)
    }
  },

  handleHubspotForm: function (form) {
		window.addEventListener("message", function(event) {
			if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
				form.classList.add('submitted')
			}
		})
  }
}

window.addEventListener('page-change', function () {
  hubspotGated.init()
})

window.addEventListener('load', function () {
  hubspotGated.init()
})
