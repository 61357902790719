/*###########################################################################
// Filter button
//#########################################################################*/

const filterBtn = {
	config: {
		filterBtn: '.filterBtn',
		filterBtnTab: '.filterBtn__tab'
	},

	elements: {},

	init() {
		const self = this

		self.elements.filterBtns = document.querySelectorAll(self.config.filterBtn)

		if (self.elements.filterBtns !== null) {
			for (let i = 0; i < self.elements.filterBtns.length; i++) {
				const filterBtn = self.elements.filterBtns[i]

				self.handleFilterBtn(filterBtn)
			}
		}
	},

	handleFilterBtn(filterBtn) {
		const self = this

		const targetTab = document.querySelector(filterBtn.getAttribute('data-target'))

		filterBtn.addEventListener('click', function() {
			let isActive = false
			if (filterBtn.classList.contains('is-active') && targetTab.classList.contains('is-active')) {
				isActive = true
			}

			// Reset tabs first
			//
			self.resetAllTabs()

			// Toggle target tab
			//
			if (targetTab.classList.contains('is-active') || isActive) {
				targetTab.classList.remove('is-active')
				filterBtn.classList.remove('is-active')
			} else {
				targetTab.classList.add('is-active')
				filterBtn.classList.add('is-active')
			}
		})
	},

	resetAllTabs() {
		const self = this

		const tabs = document.querySelectorAll(self.config.filterBtnTab)

		for (let i = 0; i < tabs.length; i++) {
			const tab = tabs[i]

			tab.classList.remove('is-active')
		}

		for (let i = 0; i < self.elements.filterBtns.length; i++) {
			const filterBtn = self.elements.filterBtns[i]

			filterBtn.classList.remove('is-active')
		}
	}
}

window.addEventListener('page-change', function () {
  filterBtn.init()
})

window.addEventListener('load', function () {
	filterBtn.init()
})
