/*###########################################################################
// Embed Fullscreen
//#########################################################################*/

const embedFullscreen = {
	config: {
		openButton: '.embed-fullscreen-open',
		closeButton: '.embed-fullscreen-close',
		embedContainer: '.embed-container__outer',
		fullscreenClass: 'embed-container__outer--fullscreen',
		visibleClass: 'is-visible'
	},

	elements: {},

	init() {
		const self = this

		self.elements.embedContainer = document.querySelector(self.config.embedContainer)
		self.elements.openButton = document.querySelector(self.config.openButton)
		self.elements.closeButton = document.querySelector(self.config.closeButton)

		if (self.elements.embedContainer !== null && self.elements.openButton !== null && self.elements.closeButton !== null) {
			self.elements.iframe = self.elements.embedContainer.querySelector('iframe')
			self.elements.iframe.setAttribute('scrolling', 'no')

			self.elements.openButton.addEventListener('click', function () {
				self.openFullscreen(this)
			})

			self.elements.closeButton.addEventListener('click', function () {
				self.closeFullscreen(this)
			})
		}
	},

	openFullscreen() {
		const self = this

		self.elements.embedContainer.classList.add(self.config.fullscreenClass)
		self.elements.openButton.classList.remove(self.config.visibleClass)
		self.elements.closeButton.classList.add(self.config.visibleClass)

		// Disable scroll
		//
		window.app.disableScroll()
		if (window.lenis !== undefined) {
			window.lenis.stop()
		}
	},

	closeFullscreen(button) {
		const self = this

		self.elements.embedContainer.classList.remove(self.config.fullscreenClass)
		self.elements.openButton.classList.add(self.config.visibleClass)
		self.elements.closeButton.classList.remove(self.config.visibleClass)

		// Enable scroll
		//
		window.app.enableScroll()
		if (window.lenis !== undefined) {
			window.lenis.start()
		}
	}
}

window.addEventListener('page-change', function () {
	embedFullscreen.init()
})

window.addEventListener('load', function () {
	embedFullscreen.init()
})
