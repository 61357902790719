/*###########################################################################
// Section nav
//#########################################################################*/

import { scrollTo } from '../utilities/_scrollTo'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
gsap.registerPlugin(ScrollTrigger)

const sectionNav = {
	config: {
		sectionNav: '.sectionNav',
		sectionNavLink: '.sectionNav__link',
		sectionNavDot: '.sectionNav__progress__dot'
	},

	elements: {},

	sectionNavLinkCount: 0,

	init() {
		const self = this

		self.elements.sectionNavs = document.querySelectorAll(self.config.sectionNav)

		if (self.elements.sectionNavs.length > 0 && document.querySelector('.sectionNav--inject') !== null) {
			for (let i = 0; i < self.elements.sectionNavs.length; i++) {
				const sectionNav = self.elements.sectionNavs[i]
				sectionNav.classList.add('loaded')
				self.handleSectionNav(sectionNav)
			}

			self.handleActiveSection(self.elements.sectionNavs[0])
		}

		if (document.querySelector('.sectionNav--inject') === null) {
			if (self.elements.sectionNavs !== null) {
				for (let i = 0; i < self.elements.sectionNavs.length; i++) {
					const sectionNav = self.elements.sectionNavs[i]

					sectionNav.parentNode.removeChild(sectionNav)
				}
			}
		}
	},

	handleSectionNav(sectionNav) {
		const self = this

		const sectionNavLinks = sectionNav.querySelectorAll(self.config.sectionNavLink)

		for (let i = 0; i < sectionNavLinks.length; i++) {
			const sectionNavLink = sectionNavLinks[i]

			self.handleSectionNavLink(sectionNavLink, sectionNav)
		}
	},

	handleSectionNavLink(sectionNavLink, sectionNav) {
		const self = this

		const targetSection = document.querySelector(sectionNavLink.getAttribute('href'))

		sectionNavLink.addEventListener('click', function(event) {
			event.preventDefault()

			if (targetSection !== null) {
				const offsetAmount = document.querySelector('.header').clientHeight
				scrollTo(targetSection, -offsetAmount)
			}
		})
	},

	handleActiveSection(sectionNav) {
		const self = this

		const sectionNavLinks = sectionNav.querySelectorAll(self.config.sectionNavLink)

		self.sectionNavLinkCount = sectionNavLinks.length

		for (let i = 0; i < sectionNavLinks.length; i++) {
			const sectionNavLink = sectionNavLinks[i]

			const targetSection = document.querySelector(sectionNavLink.getAttribute('href'))

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: targetSection,
					start: "top center",
					end: "bottom center",
					onEnter: function() {
						self.resetActiveLinks()
						self.updateActive(targetSection)
					},
					onEnterBack: function() {
						self.resetActiveLinks()
						self.updateActive(targetSection)
					}
				}
			})
		}
	},

	updateActive(targetSection) {
		const self = this

		if (targetSection !== null) {
			for (let i = 0; i < self.elements.sectionNavs.length; i++) {
				const sectionNav = self.elements.sectionNavs[i]
				const sectionNavLink = sectionNav.querySelector('.sectionNav__link[href="#' + targetSection.id + '"]')

				if (sectionNavLink !== null) {
					// Add active class
					//
					sectionNavLink.classList.add('is-active')

					// Update dot position for this particular section nav
					//
					self.updateDotPosition(sectionNavLink, sectionNav)
				}
			}
		}
	},

	resetActiveLinks() {
		const self = this

		for (let i = 0; i < self.elements.sectionNavs.length; i++) {
			const sectionNav = self.elements.sectionNavs[i]
			const sectionNavLinks = sectionNav.querySelectorAll(self.config.sectionNavLink)

			if (sectionNavLinks !== null) {
				for (let i = 0; i < sectionNavLinks.length; i++) {
					const sectionNavLink = sectionNavLinks[i]

					sectionNavLink.classList.remove('is-active')
				}
			}
		}
	},

	updateDotPosition(activeSectionNavLink, sectionNav) {
		const self = this

		const dot = sectionNav.querySelector(self.config.sectionNavDot)

		dot.style.top = activeSectionNavLink.offsetTop + 'px'
	},
}

window.addEventListener('page-change', function () {
	setTimeout(() => {
		const sectionNavs = document.querySelectorAll('.sectionNav')
		if (sectionNavs !== null) {
			for (let i = 0; i < sectionNavs.length; i++) {
				const sectionNav = sectionNavs[i]
				sectionNav.parentNode.removeChild(sectionNav)
			}
		}

		setTimeout(function() {
			const sectionNavInject = document.querySelector('.sectionNav--inject')
			if (sectionNavInject !== null) {
				const immerserContainer = document.querySelector('div[data-immerser]')
				const sectionNavEl = document.createElement('div')
				sectionNavEl.classList.add('sectionNav__hold')
				immerserContainer.appendChild(sectionNavEl)
				document.querySelector('.sectionNav__hold').outerHTML = JSON.parse(sectionNavInject.getAttribute('data-html'))
			}
		}, 250)
	}, 50)

  setTimeout(function() {
		sectionNav.init()
	}, 1000)
})

window.addEventListener('load', function () {
	setTimeout(function() {
		const sectionNavInject = document.querySelector('.sectionNav--inject')
		if (sectionNavInject !== null) {
			const immerserContainer = document.querySelector('div[data-immerser]')
			const sectionNavEl = document.createElement('div')
			sectionNavEl.classList.add('sectionNav__hold')
			immerserContainer.appendChild(sectionNavEl)
			document.querySelector('.sectionNav__hold').outerHTML = JSON.parse(sectionNavInject.getAttribute('data-html'))
		}
	}, 250)

	setTimeout(function() {
		sectionNav.init()
	}, 1000)
})
