/*###########################################################################
// Hero home
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import "../vendor/gsap/_splitText"
import '../vendor/gsap/_blurPlugin'
import { throttle } from "../utilities/_throttleDebounce"
gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(SplitText)

const heroHome = {
  config: {
    heroHome: '.heroHome',
		heroHomeInner: '.heroHome__inner',
		heroHomeVideo: '.heroHome__video__wrapper',
    heroHomeHeading: '.heroHome__heading'
  },

  elements: {},

	state: {
		paddingTopInitial: 0
	},

  init() {
    const self = this

    self.elements.heroHome = document.querySelector(self.config.heroHome)

    if (self.elements.heroHome !== null) {
			self.elements.heroHomeInner = self.elements.heroHome.querySelector(self.config.heroHomeInner)
			self.elements.heroHomeVideo = self.elements.heroHome.querySelector(self.config.heroHomeVideo)

      self.elements.heroHomeHeading = self.elements.heroHome.querySelector(self.config.heroHomeHeading)
			self.elements.heroHomeVideo = self.elements.heroHome.querySelector(self.config.heroHomeVideo)

      // Set loaded class
      //
      self.elements.heroHome.classList.add('has-loaded')

      // Handle 100vh height
      //
      setTimeout(() => {
				self.elements.heroHomeInner.style.height = (window.innerHeight - document.querySelector('.header').clientHeight) + 'px'
			}, 250)

			setTimeout(() => {
				self.elements.heroHomeInner.style.height = (window.innerHeight - document.querySelector('.header').clientHeight) + 'px'
			}, 2000)

      window.addEventListener('resize', throttle(function() {
				self.elements.heroHomeInner.style.height = (window.innerHeight - document.querySelector('.header').clientHeight) + 'px'
      }, 250))

			// Handle hero main
      //
      self.handleHeroMain()

      // Handle hero heading
      //
      self.handleHeroHeading()
    }
  },

	handleHeroMain() {
		const self = this

    let tl = gsap.timeline()

		const getHomeVideoWidth = () => {
			if (window.innerWidth < 768) {
				return document.body.clientWidth - 32
			} else if (window.innerWidth >= 768 && window.innerWidth < 1024) {
				return document.body.clientWidth - 48
			} else {
				return document.body.clientWidth - 64
			}
		}

		tl.set(self.elements.heroHomeVideo, {
			width: getHomeVideoWidth() + 'px',
			height: (window.innerHeight - document.querySelector('.header').clientHeight) - (window.innerWidth < 768 ? 40 : 80) + 'px'
		})

		tl.to(self.elements.heroHomeVideo, {
			duration: 1,
			opacity: 1,
      ease: "power1.in",
    })

		tl.to(self.elements.heroHomeVideo, {
			duration: 1,
			width: window.innerWidth,
			scale: 1.25,
			height: '100%',
			borderTopLeftRadius: 0,
			borderTopRightRadius: 0,
      ease: "power1.in",
    }, '+=0.5')
	},

  handleHeroHeading() {
    const self = this

    let tl = gsap.timeline()

    let split = new SplitText(self.elements.heroHomeHeading, {
      type: "words",
    })

    tl.from(split.words, {
      delay: 0.5,
      duration: 0.6,
      opacity: 0,
			blur: 15,
			x: -40,
      ease: "power1.out",
      stagger: 0.05
    })
  }
}

window.addEventListener('page-change', function() {
	heroHome.init()
})

window.addEventListener('load', function() {
  heroHome.init()
})
