/*###########################################################################
// Hubspot Forms
//#########################################################################*/

import { ScrollTrigger } from "gsap/ScrollTrigger";

const hubspotForms = {
  config: {
    hubspotForm: '.form--hubspot'
  },

  elements: {},

  init() {
    const self = this

    self.elements.hubspotForms = document.querySelectorAll(self.config.hubspotForm)

    if (self.elements.hubspotForms.length) {
      self.elements.hubspotForms.forEach((item, i) => {
        self.handleHubspotForm(item, i)
      })
    }
  },

  handleHubspotForm(hubspotForm, index) {
    const self = this

    // Only add form to container if form doesn't already exist
		//
    if (hubspotForm.children.length === 0) {
			if (!document.querySelector('script[src="//js-eu1.hsforms.net/forms/embed/v2.js"]')) {
				self.addHubspotScript()
			} else {
				document.querySelector('script[src="//js-eu1.hsforms.net/forms/embed/v2.js"]').parentNode.removeChild(document.querySelector('script[src="//js-eu1.hsforms.net/forms/embed/v2.js"]'))

				self.addHubspotScript()
			}

      hubspotForm.setAttribute('data-form-index', index)

      const portalID = hubspotForm.getAttribute('data-portal-id')
      const formID = hubspotForm.getAttribute('data-form-id')

      if (portalID && formID) {
        window.addEventListener('hubspot-init', function() {
					hbspt.forms.create({
						portalId: portalID,
						formId: formID,
						target: `.form--hubspot[data-form-index="${index}"]`,
						onFormReady: function() {
							setTimeout(function() {
								ScrollTrigger.sort()
								ScrollTrigger.refresh()

								const hsLoadEvent = new CustomEvent('hs-loaded')
								window.dispatchEvent(hsLoadEvent)
							}, 250)
						}
					})
				})
      } else {
        console.error('Portal ID and Form ID are required to show Hubspot Form')
      }
    }
  },

	addHubspotScript() {
		const self = this

		const script = document.createElement('script')
		script.charset = 'utf-8'
		script.type = 'text/javascript'
		script.src = '//js-eu1.hsforms.net/forms/embed/v2.js'

		// Append the script to the head
		document.head.appendChild(script)

		script.onload = () => {
			const hubspotInitEvent = new CustomEvent('hubspot-init')
			window.dispatchEvent(hubspotInitEvent)
		}
	}
}

// Event listeners to trigger the form initialisation
window.addEventListener('page-change', function () {
  hubspotForms.init()
})

window.addEventListener('load', function () {
  hubspotForms.init()
})
