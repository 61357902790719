/*###########################################################################
// Slider image w text
//#########################################################################*/

import Swiper from "swiper"
import { Autoplay, EffectCreative, EffectFade } from 'swiper/modules'
import { throttle, debounce } from "@js/utilities/_throttleDebounce"

const sliderImageWText = {
  config: {
    sliderImageWText: '.sliderImageWText'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderImageWText = document.querySelectorAll(self.config.sliderImageWText)

    if (self.elements.sliderImageWText !== null) {
      for (let i = 0; i < self.elements.sliderImageWText.length; i++) {
        const sliderImageWText = self.elements.sliderImageWText[i]

        self.handleSliderImageWText(sliderImageWText)
      }
    }
  },

  initSlider(slider) {
    const self = this

		let swiper = null

		const prevBtn = slider.parentNode.querySelector('.slider__control--prev')
    const nextBtn = slider.parentNode.querySelector('.slider__control--next')
		const captionEl = slider.parentNode.querySelector('.sliderImageWText__caption')

		const loop = slider.getAttribute('data-loop') == 'true' ? true : false
		const autoplay = slider.getAttribute('data-autoplay') == 'true' ? true : false

		if (autoplay) {
			swiper = new Swiper(slider, {
				modules: [Autoplay, EffectCreative],
				effect: "creative",
				speed: window.innerWidth < 1024 ? 1000 : 1250,
				autoplay: {
					delay: 2000,
				},
				creativeEffect: {
					prev: {
						shadow: false,
						translate: ["-20%", 0, -1],
					},
					next: {
						translate: ["100%", 0, 0],
					},
				},
				loop: loop,
				slidesPerView: 1,
				spaceBetween: 0,
				autoHeight: true
			})
		} else {
			swiper = new Swiper(slider, {
				modules: [EffectCreative],
				effect: "creative",
				speed: window.innerWidth < 1024 ? 1000 : 1250,
				creativeEffect: {
					prev: {
						shadow: false,
						translate: ["-20%", 0, -1],
					},
					next: {
						translate: ["100%", 0, 0],
					},
				},
				loop: loop,
				slidesPerView: 1,
				spaceBetween: 0,
				autoHeight: true
			})
		}

    if (swiper && !swiper.destroyed && prevBtn !== null && nextBtn !== null && captionEl !== null) {
			if (swiper.isBeginning) {
				prevBtn.classList.add('is-disabled')
			}

			captionEl.innerHTML = JSON.parse(swiper.slides[swiper.realIndex].getAttribute('data-caption'))

			swiper.on('slideChange', function() {
				if (swiper.isBeginning) {
					prevBtn.classList.add('is-disabled')
				}

				if (swiper.isEnd) {
					nextBtn.classList.add('is-disabled')
				}

				captionEl.innerHTML = JSON.parse(swiper.slides[swiper.realIndex].getAttribute('data-caption'))
			})

			swiper.on('fromEdge', function() {
				prevBtn.classList.remove('is-disabled')
				nextBtn.classList.remove('is-disabled')
			})

			swiper.on('reachBeginning', function () {
				if (swiper.isBeginning) {
					prevBtn.classList.add('is-disabled')
				}
			})

			swiper.on('reachEnd', function () {
				if (swiper.isEnd) {
					nextBtn.classList.add('is-disabled')
				}
			})

			prevBtn.addEventListener('click', function (e) {
				swiper.slidePrev()
			})

			nextBtn.addEventListener('click', function (e) {
				swiper.slideNext()
			})

      // Workaround for weird bug of miscalculations
      //
      swiper.update()
    }

    return swiper
  },

  handleSliderImageWText(slider) {
    const self = this

    let swiper = self.initSlider(slider)
    let width = window.innerWidth

    window.addEventListener("resize", debounce(function() {
      if (window.innerWidth !== width) {
        setTimeout(function() {
          swiper.update()
          swiper.updateProgress()
        }, 500)

        width = window.innerWidth
      }
    }, 500))
  }
}

window.addEventListener('page-change', function() {
	setTimeout(function() {
    sliderImageWText.init()
  }, 500)
})

window.addEventListener('load', function () {
  sliderImageWText.init()
})
