/*###########################################################################
// YT Replace
//#########################################################################*/

const ytReplace = {
	config: {
		element: '[yt-replace]'
	},

	elements: {},

	init() {
		const self = this

		self.elements.ytReplace = document.querySelectorAll(self.config.element)

		if (self.elements.ytReplace !== null && self.elements.ytReplace.length) {
			for (let i = 0; i < self.elements.ytReplace.length; i++) {
				const ytReplace = self.elements.ytReplace[i]
				self.handleClick(ytReplace)
			}
		}
	},

	handleClick(ytReplace) {
		ytReplace.addEventListener('click', function () {
			const section = ytReplace.closest('section')
			if (!section) return

			const videoId = ytReplace.getAttribute('yt-replace')
			if (!videoId) return

			const iframe = document.createElement('iframe')
			iframe.setAttribute('src', `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`)
			iframe.setAttribute('frameborder', '0')
			iframe.setAttribute('allowfullscreen', '')
			iframe.setAttribute('class', 'absolute z-30 top-0 left-0 w-full h-full')

			section.appendChild(iframe)

			iframe.addEventListener('mouseenter', function() {
				// Hide cursor on iframe
				//
				let cursorHideEvent = new Event('cursor-hide')
				window.dispatchEvent(cursorHideEvent)
			})

			iframe.addEventListener('mouseleave', function() {
				// Show cursor on iframe
				//
				let cursorShowEvent = new Event('cursor-show')
				window.dispatchEvent(cursorShowEvent)
			})
		})
	}
}

window.addEventListener('page-change', function () {
	ytReplace.init()
})

window.addEventListener('load', function () {
	ytReplace.init()
})
