/*###########################################################################
// Animations
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import '../vendor/gsap/_blurPlugin'
import '../vendor/gsap/_splitText'
import { inViewport } from '../utilities/_inViewport'

gsap.registerPlugin(SplitText)
gsap.registerPlugin(ScrollTrigger)

const animations = {
	config: {
		tblurIn: '[t-blur-in]',
		fadeIn: '[fade-in]',
		imgFadeIn: '[img-fade-in]',
		gridLines: '.gridLines.gridLines--animate'
	},

	startOffset: 1.25,

	elements: {},

	pageChange: false,

	init(pageChange = false) {
		const self = this

		// Set page change state
		//
		self.pageChange = pageChange

		// Blur in
		//
		self.elements.tblurIn = document.querySelectorAll(self.config.tblurIn)
		if (self.elements.tblurIn !== null) {
			for (let i = 0; i < self.elements.tblurIn.length; i++) {
				const tblurIn = self.elements.tblurIn[i]

				self.handleTBlurIn(tblurIn)
			}
		}

		// Fade In
		//
		self.elements.fadeIn = document.querySelectorAll(self.config.fadeIn)
		if (self.elements.fadeIn !== null) {
			for (let i = 0; i < self.elements.fadeIn.length; i++) {
				const fadeIn = self.elements.fadeIn[i]

				self.handleFadeIn(fadeIn)
			}
		}

		// Image fade in
		//
		self.elements.imgFadeIn = document.querySelectorAll(self.config.imgFadeIn)
		if (self.elements.imgFadeIn !== null) {
			for (let i = 0; i < self.elements.imgFadeIn.length; i++) {
				const imgFadeIn = self.elements.imgFadeIn[i]

				self.handleImgFadeIn(imgFadeIn)
			}
		}

		// Grid lines
		//
		self.elements.gridLines = document.querySelectorAll(self.config.gridLines)
		if (self.elements.gridLines !== null) {
			for (let i = 0; i < self.elements.gridLines.length; i++) {
				const gridLine = self.elements.gridLines[i]

				self.handleGridLine(gridLine)
			}
		}
	},

	handleTBlurIn(tBlurIn) {
		const self = this

		const startOffset = tBlurIn.hasAttribute('start-offset') ? tBlurIn.getAttribute('start-offset') : self.startOffset

		if (tBlurIn !== null) {
			const tlBlurIn = gsap.timeline({
				scrollTrigger: {
					trigger: tBlurIn,
					start: "top +=" + (window.innerHeight / startOffset) + " middle",
					end: "top center",
				}
			})

			const splitBlurIn = new SplitText(tBlurIn, { type: "words" });
			const blurInWords = splitBlurIn.words;

			tlBlurIn.from(blurInWords, {
				duration: 0.8,
				delay: self.calculateDelay(tBlurIn),
				blur: 15,
				alpha: 0,
				x: window.innerWidth < 1024 ? -20 : -40,
				ease: "power1.out",
				stagger: 0.1
			})
		}
	},

	handleFadeIn(fadeIn) {
		const self = this

		const startOffset = fadeIn.hasAttribute('start-offset') ? fadeIn.getAttribute('start-offset') : self.startOffset
		const fadeInLgDown = fadeIn.hasAttribute('desktop-down') ? true : false

		if (fadeIn !== null && !fadeInLgDown || fadeIn !== null && fadeInLgDown && window.innerWidth < 1024) {
			const tlFadeIn = gsap.timeline({
				scrollTrigger: {
					trigger: fadeIn,
					start: "top +=" + (window.innerHeight / startOffset) + " middle",
					end: "top center",
				}
			})

			if (fadeIn.getAttribute('dir') == 'fromRight' && window.innerWidth >= 1024) {
				tlFadeIn.from(fadeIn, {
					duration: 0.8,
					delay: self.calculateDelay(fadeIn),
					alpha: 0,
					x: window.innerWidth < 1024 ? 20 : 40,
					ease: "power1.out"
				})
			} else if (fadeIn.getAttribute('dir') == 'none' && window.innerWidth >= 1024) {
				tlFadeIn.from(fadeIn, {
					duration: 0.8,
					delay: self.calculateDelay(fadeIn),
					alpha: 0,
					ease: "power1.out"
				})
			} else {
				tlFadeIn.from(fadeIn, {
					duration: 0.8,
					delay: self.calculateDelay(fadeIn),
					alpha: 0,
					x: window.innerWidth < 1024 ? -20 : -40,
					ease: "power1.out"
				})
			}
		}
	},

	handleImgFadeIn(imgFadeIn) {
		const self = this

		if (imgFadeIn !== null) {
			const tlImgMain = gsap.timeline({
				scrollTrigger: {
					trigger: imgFadeIn,
					start: "top +=" + (window.innerHeight / self.startOffset) + " middle",
					end: "top center",
				}
			})

			tlImgMain.from(imgFadeIn, {
				duration: 0.8,
				delay: self.calculateDelay(imgFadeIn),
				blur: 15,
				alpha: 0,
				scale: 1.25,
				ease: "power1.out"
			})
		}
	},

	handleGridLine(gridLine) {
		const self = this

		if (gridLine !== null && window.innerWidth >= 1024) {
			const tlGridLine = gsap.timeline({
				scrollTrigger: {
					trigger: gridLine.parentNode.parentNode,
					start: "top +=" + (window.innerHeight / self.startOffset) + " middle",
					end: "bottom center",
					scrub: true
				}
			})

			tlGridLine.from(gridLine.querySelector('span:nth-child(1)'), {
				duration: 2,
				x: -window.innerWidth*1.5,
				ease: "power1.out"
			})

			tlGridLine.from(gridLine.querySelector('span:nth-child(2)'), {
				duration: 2,
				y: -window.innerHeight*1.75,
				ease: "power1.out"
			}, "-=2")

			tlGridLine.from(gridLine.querySelector('span:nth-child(3)'), {
				duration: 1.25,
				x: -window.innerHeight,
				ease: "power1.out"
			}, "-=2")

			tlGridLine.from(gridLine.querySelector('span:nth-child(4)'), {
				duration: 1.25,
				y: -window.innerHeight,
				ease: "power1.out"
			}, "-=2")
		}
	},

	calculateDelay(el) {
		const self = this

		// const debugInfo = {
		// 	element: el,
		// 	top: el.getBoundingClientRect().top,
		// 	isInViewport: el.getBoundingClientRect().top < window.innerHeight
		// }
		// console.log(debugInfo)

		// Check if element is in viewport and page change is true (to account for page transitions)
		//
		if (el.getBoundingClientRect().top < window.innerHeight && self.pageChange === true) {
			return 0.8
		} else {
			return 0
		}
	}
}

window.addEventListener('page-change', function() {
	animations.init(true)
})

window.addEventListener('load', function () {
	animations.init()
})
