/*###########################################################################
// App
//#########################################################################*/

/* CSS
//------------------*/

import "@css/app.css";

/* Barba
//------------------*/

import '@js/_barba'

/* App
//------------------*/

window.app = {
  disableScroll() {
    document.body.style.overflow = 'hidden'
  },

  enableScroll() {
    document.body.style.overflow = ''
  }
}

/* Components
//------------------*/

import '@js/components/index'

/* Vendor
//------------------*/

import '@js/vendor/index'

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}
