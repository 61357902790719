/*###########################################################################
// Barba
//#########################################################################*/

import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Lenis from '@studio-freight/lenis';
import barba from '@barba/core';
import { scrollTo } from './utilities/_scrollTo'

const pageTransition = document.querySelector('.pageTransition')
const pageTransitionPanels = pageTransition.querySelectorAll('.pageTransition__panel')

const tlPageTransition = gsap.timeline({
	paused: true,
	onComplete: function(timeline) {
		tlPageTransition.restart()
		tlPageTransition.pause()
	}
})

for (let i = 0; i < pageTransitionPanels.length; i++) {
	const panel = pageTransitionPanels[i]

	if (i == 0) {
		tlPageTransition.to(panel, {
			duration: 0.8,
			ease: "power1.out",
			y: 0
		})
	} else {
		tlPageTransition.to(panel, {
			duration: 0.8,
			ease: "power1.out",
			y: 0
		}, ">-" + (0.75 - ((i / 100) * 5)))
	}

	tlPageTransition.to(panel, {
		duration: 0.8,
		ease: "power1.out",
		y: -window.innerHeight,
	}, ">-" + (0.75 - ((i / 100) * 5)))
}

// init lenis
window.lenis = new Lenis({
	syncTouch: true
})

window.addEventListener('load', function () {
  window.lenis.on('scroll', (e) => {
    // console.log(e)
  })

  function raf(time) {
    window.lenis.raf(time)
    requestAnimationFrame(raf)
  }

  requestAnimationFrame(raf)
})

barba.init({
	transitions: [{
		sync: true,
		name: 'opacity-transition',
		beforeLeave(data) {
			tlPageTransition.play()

			const beforeEnterEvent = new CustomEvent('before-page-change')
			window.dispatchEvent(beforeEnterEvent)
		},
		enter(data) {
			setTimeout(() => {
				window.lenis.scrollTo(0, {
					duration: 0.1
				})
			}, 250)
		},
		after(data) {
			const enterEvent = new CustomEvent('page-change')
			window.dispatchEvent(enterEvent)
		},
		afterEnter({ trigger }) {
			setTimeout(function() {
				if (trigger && trigger.hash) {
					const target = document.querySelector(trigger.hash)

					if (target !== null) {
						if (window.lenis !== undefined) {
							window.lenis.stop()
						}

						const offsetAmount = document.querySelector('.header').clientHeight + (window.innerWidth < 1024 ? 140 : 80)
						scrollTo(target, -offsetAmount)

						setTimeout(function() {
							if (window.lenis !== undefined) {
								window.lenis.start()
							}
						}, 1000)
					}
				}
			}, 1500)
		}
	}],
	preventRunning: true,
	timeout: 7500,
	prevent: ({ href }) => {
    // Prevent if the link's href matches the current page URL
    return href === window.location.href;
  }
})

barba.hooks.leave(() => {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, 250)
  })
})

barba.hooks.beforeEnter(({ next }) => {
	setTimeout(() => {
		if (typeof htmx !== 'undefined') {
			htmx.process(next.container)
		}
	}, 250)
})

window.addEventListener('load', function() {
	setTimeout(function() {
		ScrollTrigger.sort()
		ScrollTrigger.refresh()
	}, 500)
})

window.addEventListener('page-change', function() {
	setTimeout(function() {
		ScrollTrigger.sort()
		ScrollTrigger.refresh()
	}, 500)
})
