/*###########################################################################
// Image Slider
//#########################################################################*/

import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules';

const sliderImage = {
  config: {
    sliderImage: '.sliderImage'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderImages = document.querySelectorAll(self.config.sliderImage)

    if (self.elements.sliderImages !== null) {
      for (let i = 0; i < self.elements.sliderImages.length; i++) {
        const sliderImage = self.elements.sliderImages[i]

        self.handleSliderImage(sliderImage)
      }
    }
  },

  handleSliderImage(slider) {
    const self = this

		const prevBtn = slider.parentNode.querySelector('.slider__control--prev')
    const nextBtn = slider.parentNode.querySelector('.slider__control--next')
		const captionEl = slider.parentNode.querySelector('.sliderImage__caption')

    const swiper = new Swiper(slider, {
			speed: window.innerWidth < 1024 ? 1000 : 1250,
      slidesPerView: 1,
      spaceBetween: 32,
      loop: false,
      autoHeight: false,
			centeredSlides: true
    })

		if (swiper.isBeginning) {
      prevBtn.classList.add('is-disabled')
    }

		captionEl.innerText = swiper.slides[swiper.realIndex].getAttribute('data-caption');

    swiper.on('slideChange', function() {
      if (swiper.isBeginning) {
        prevBtn.classList.add('is-disabled')
      }

      if (swiper.isEnd) {
        nextBtn.classList.add('is-disabled')
      }

			captionEl.innerText = swiper.slides[swiper.realIndex].getAttribute('data-caption');
    })

    swiper.on('fromEdge', function() {
      prevBtn.classList.remove('is-disabled')
      nextBtn.classList.remove('is-disabled')
    })

    swiper.on('reachBeginning', function () {
      if (swiper.isBeginning) {
        prevBtn.classList.add('is-disabled')
      }
    })

    swiper.on('reachEnd', function () {
      if (swiper.isEnd) {
        nextBtn.classList.add('is-disabled')
      }
    })

    prevBtn.addEventListener('click', function (e) {
      swiper.slidePrev()
    })

    nextBtn.addEventListener('click', function (e) {
      swiper.slideNext()
    })
  }
}

window.addEventListener('page-change', function () {
  sliderImage.init()
})

window.addEventListener('load', function () {
  sliderImage.init()
})
