import { gsap } from "gsap"
import { throttle } from "../utilities/_throttleDebounce";

const cursor = {
	state: {
		cursorSize: 'default',
		cursorColour: 'default',
		cursorType: 'default',
		isVisible: false,
		isHidden: false,
	},

	init() {
		this.handleCursor()
	},

	elements: {},

	handleCursor() {
		const self = this

		let cursorEls = document.querySelectorAll('[cursor]')
		for (let i = 0; i < cursorEls.length; i++) {
			const cursorEl = cursorEls[i]
			self.handleCursorEl(cursorEl)
		}

		self.elements.cursor = document.querySelector(".cursor")
		self.state.isVisible = false
		self.elements.cursor.style.opacity = '0'

		// On mouse move
		//
		window.addEventListener("mousemove", function(e) {
			if (window.innerWidth >= 1024) {
				if (!self.state.isVisible && !self.state.isHidden) {
					self.state.isVisible = true

					setTimeout(function() {
						self.elements.cursor.style.opacity = '1'
					}, 500)
				}

				gsap.to(self.elements.cursor, {
					top: e.clientY + "px",
					left: e.clientX + "px",
					duration: 0.4,
					ease: "power2.out"
				});
			}
		})

		window.addEventListener('cursor-hide', function() {
			self.state.isHidden = true

			self.animateOut()
		})

		window.addEventListener('cursor-show', function() {
			self.state.isHidden = false

			self.animateIn()
		})
	},

	handleCursorEl(cursorEl) {
		const self = this

		cursorEl.addEventListener("mouseenter", function(e) {
			// Handle size
			//
			self.state.cursorSize = cursorEl.getAttribute('cursor-size')
			self.elements.cursor.classList.add(self.state.cursorSize)

			// Handle colour
			//
			self.state.cursorColour = cursorEl.getAttribute('cursor-colour')
			self.elements.cursor.classList.add(self.state.cursorColour)

			// Handle type
			//
			self.state.cursorType = cursorEl.getAttribute('cursor-type')
			self.elements.cursor.classList.add(self.state.cursorType)
		})

		cursorEl.addEventListener("mousemove", function(e) {
			if (self.state.cursorType == 'default') {
				// Handle size
				//
				self.state.cursorSize = cursorEl.getAttribute('cursor-size')
				self.elements.cursor.classList.add(self.state.cursorSize)

				// Handle colour
				//
				self.state.cursorColour = cursorEl.getAttribute('cursor-colour')
				self.elements.cursor.classList.add(self.state.cursorColour)

				// Handle type
				//
				self.state.cursorType = cursorEl.getAttribute('cursor-type')
				self.elements.cursor.classList.add(self.state.cursorType)
			}
		})

		cursorEl.addEventListener("mouseleave", function(e) {
			// Handle size
			//
			self.state.cursorSize = 'default'
			self.elements.cursor.classList.remove(cursorEl.getAttribute('cursor-size'))

			// Handle colour
			//
			self.state.cursorColour = 'default'
			self.elements.cursor.classList.remove(cursorEl.getAttribute('cursor-colour'))

			// Handle type
			//
			self.state.cursorType = 'default'
			self.elements.cursor.classList.remove(cursorEl.getAttribute('cursor-type'))
		})

		window.addEventListener('cursor-reset', function() {
			// Handle size
			//
			self.state.cursorSize = 'default'
			self.elements.cursor.classList.remove(cursorEl.getAttribute('cursor-size'))

			// Handle colour
			//
			self.state.cursorColour = 'default'
			self.elements.cursor.classList.remove(cursorEl.getAttribute('cursor-colour'))

			// Handle type
			//
			self.state.cursorType = 'default'
			self.elements.cursor.classList.remove(cursorEl.getAttribute('cursor-type'))

			// Reset classes
			//
			self.resetCursorClasses(cursorEl)
		})
	},

	resetCursorClasses(cursorEl) {
		const self = this

		// Types
		self.elements.cursor.classList.remove('nextProject')
		self.elements.cursor.classList.remove('play')
		self.elements.cursor.classList.remove('arrow')
		self.elements.cursor.classList.remove('arrowFlipped')

		// Sizes
		self.elements.cursor.classList.remove('md')
		self.elements.cursor.classList.remove('lg')

		// Colours
		self.elements.cursor.classList.remove('white')
		self.elements.cursor.classList.remove('primary')
	},

	animateOut() {
		const self = this

		self.elements.cursor.style.opacity = '0'
		self.state.isVisible = false
	},

	animateIn() {
		const self = this

		self.state.isVisible = true
		setTimeout(function() {
			self.elements.cursor.style.opacity = '1'
		}, 25)
	}
}

window.addEventListener('page-change', function() {
	setTimeout(function() {
		cursor.init()

		setTimeout(function() {
			let resetCursorEvent = new Event('cursor-reset')
			window.dispatchEvent(resetCursorEvent)
		}, 50)
	}, 100)
})

if (typeof htmx !== 'undefined') {
	htmx.on('htmx:afterSwap', function (event) {
		setTimeout(function() {
			cursor.init()
		}, 250)

		// Reset cursor incase cursor gets stuck in enlarged state
		//
		let resetCursorEvent = new Event('cursor-reset')
		window.dispatchEvent(resetCursorEvent)
	})
}

window.addEventListener('load', () => {
	setTimeout(function() {
		cursor.init()
	}, 250)
})
