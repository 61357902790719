/*###########################################################################
// Stacking elements
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import '../vendor/gsap/_blurPlugin'

gsap.registerPlugin(ScrollTrigger)

const hwwScroller = {
  config: {
		sectionsContainer: '[hww-sections]',
		section: '[hww-section]',
		img: '[section-img]',
		text: '[section-text]',
		btn: '[section-btn]',
		heading: '[section-heading]'
  },

  elements: {},

  init() {
    const self = this

		const sectionsContainer = document.querySelectorAll(self.config.sectionsContainer)

		if (window.innerWidth >= 768) {
			sectionsContainer.forEach((sectionsContainer) => {
				self.handleScrollerInstance(sectionsContainer)
			})
		}
  },

	handleScrollerInstance(sectionsContainer) {
		const self = this

		const sections = sectionsContainer.querySelectorAll(self.config.section)

		let scrollWidth = 0

		sections.forEach(section => {
			scrollWidth += section.clientWidth
		})

		scrollWidth = scrollWidth - (window.innerWidth)

		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: sectionsContainer,
				start: "top top",
				end: () => `+=${scrollWidth}`,
				pin: true,
				pinSpacing: true,
				scrub: 0.05,
			}
		})

		tl.to(sectionsContainer, {
			duration: 1,
			delay: 0.0075,
			x: `-${scrollWidth}`,
			ease: "power1.out"
		})

		setTimeout(function() {
			ScrollTrigger.refresh()
			ScrollTrigger.update()
		}, 1000)
	}
}

window.addEventListener('page-change', function() {
	setTimeout(function() {
    hwwScroller.init()
  }, 500)
})

window.addEventListener('load', function () {
  setTimeout(function() {
    hwwScroller.init()
  }, 500)
})
