/*###########################################################################
// Slider featured
//#########################################################################*/

import Swiper from 'swiper'
import { throttle } from '../utilities/_throttleDebounce'

const sliderFeatured = {
  config: {
    sliderFeatured: '.sliderFeatured'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderFeatured = document.querySelectorAll(self.config.sliderFeatured)

    if (self.elements.sliderFeatured !== null) {
      for (let i = 0; i < self.elements.sliderFeatured.length; i++) {
        const sliderFeatured = self.elements.sliderFeatured[i]

        self.handleSliderFeatured(sliderFeatured)
      }
    }
  },

  handleSliderFeatured(slider) {
    const self = this

		const prevBtn = slider.closest('.sliderFeatured__section').querySelector('.slider__control--prev')
    const nextBtn = slider.closest('.sliderFeatured__section').querySelector('.slider__control--next')

		const nav = slider.closest('.sliderFeatured__section').querySelector('.sliderFeaturedNav')
		const progressBar = slider.closest('.sliderFeatured__section').querySelector('.slider__progress__bar')

    const swiper = new Swiper(slider, {
			speed: window.innerWidth < 1024 ? 1000 : 1250,
      slidesPerView: 1,
			watchSlidesProgress: true,
			centeredSlides: true,
      spaceBetween: 32,
      loop: false,
			autoHeight: true,
			allowTouchMove: true
    })

		const swiperNav = new Swiper(nav, {
			speed: 750,
      slidesPerView: nav.classList.contains('sliderFeaturedNav--slide-full') ? 1 : 'auto',
			watchSlidesProgress: true,
			centeredSlides: true,
      spaceBetween: 12,
      loop: false,
			autoHeight: true,
			allowTouchMove: true
    })

		setTimeout(() => {
			self.controlsVisibleCheck(swiper, prevBtn, nextBtn)

			window.addEventListener('resize', throttle(function () {
				self.controlsVisibleCheck(swiper, prevBtn, nextBtn)
			}, 250))
		}, 500)

		if (swiper.isBeginning) {
      prevBtn.classList.add('is-disabled')
    }

    swiper.on('slideChange', function() {
      if (swiper.isBeginning) {
        prevBtn.classList.add('is-disabled')
      }

      if (swiper.isEnd) {
        nextBtn.classList.add('is-disabled')
      }

			// Sync nav slider
			//
			swiperNav.slideTo((swiper.realIndex), 500)

			// Update progress bar
			//
			progressBar.style.width = ((100 / swiper.slides.length) * (swiper.activeIndex + 1)) + '%'

			setTimeout(function() {
				swiperNav.updateProgress()
				swiperNav.updateSize()
				swiperNav.updateSlides()

				swiperNav.slideTo(swiper.realIndex, 250)
			}, 200)
    })

		swiperNav.on('slideChange', function() {
      if (swiperNav.isBeginning) {
        prevBtn.classList.add('is-disabled')
      }

      if (swiperNav.isEnd) {
        nextBtn.classList.add('is-disabled')
      }

			swiper.slideTo((swiperNav.realIndex), 500)

			setTimeout(function() {
				swiperNav.updateProgress()
				swiperNav.updateSize()
				swiperNav.updateSlides()

				swiperNav.slideTo(swiper.realIndex, 250)
			}, 200)
    })

		setTimeout(function() {
			swiperNav.updateProgress()
			swiperNav.updateSize()
			swiperNav.updateSlides()
			swiperNav.slideTo(swiper.realIndex, 100)
		}, 750)

    swiper.on('fromEdge', function() {
      prevBtn.classList.remove('is-disabled')
      nextBtn.classList.remove('is-disabled')
    })

    swiper.on('reachBeginning', function () {
      if (swiper.isBeginning) {
        prevBtn.classList.add('is-disabled')
      }
    })

    swiper.on('reachEnd', function () {
      if (swiper.isEnd) {
        nextBtn.classList.add('is-disabled')
      }
    })

    prevBtn.addEventListener('click', function (e) {
      swiper.slidePrev()
    })

    nextBtn.addEventListener('click', function (e) {
      swiper.slideNext()
    })
  },

	controlsVisibleCheck(swiper, prevBtn, nextBtn) {
    const totalSlides = swiper.slides.length
    const slidesPerView = swiper.params.slidesPerView

    if (totalSlides <= slidesPerView) {
      prevBtn.classList.add('slider__controls--hidden')
			nextBtn.classList.add('slider__controls--hidden')
      swiper.disable()
    } else {
      prevBtn.classList.remove('slider__controls--hidden')
			nextBtn.classList.remove('slider__controls--hidden')
      swiper.enable()
    }
  },
}

window.addEventListener('page-change', function() {
	setTimeout(function() {
    sliderFeatured.init()
  }, 500)
})

window.addEventListener('load', function () {
  sliderFeatured.init()
})
