// Add an event listener to listen for messages from the configurator
window.addEventListener('message', async (event) => {
	// Ensure that the message is coming from a trusted source
	if (event.origin === 'https://deployed.dynamaker.com') {
		try {
			// Parse the incoming message data
			const quotePDFData = typeof event.data === 'string' ? JSON.parse(event.data) : event.data;

			// Check if this is a quote form submission
			if (quotePDFData.action === "request_quote_form_submit") {
				const data = quotePDFData.data;

				if (!data) {
					console.error('No form data received');
					return;
				}

				console.log('data', data);

				// Map data to form fields
				const fieldMappings = {
					'firstname': data.firstname,
					'lastname': data.lastname,
					'company': data.company,
					'site_post_code': data.site_post_code,
					'phone': data.phone,
					'email': data.email
				};

				// Populate form fields
				Object.entries(fieldMappings).forEach(([fieldName, value]) => {
					const input = document.querySelector(`input[name="${fieldName}"]`);
					if (input) {
						input.value = value || '';
					} else {
						console.warn(`Input field with name="${fieldName}" not found`);
					}
				});

				// Find and submit the form
				const form = document.querySelector('.form--hubspot');
				if (form) {
					// Set up mutation observer to watch for validation errors
					const observer = new MutationObserver((mutations) => {
						mutations.forEach((mutation) => {
							if (mutation.type === 'childList') {
								const errorMessages = form.querySelectorAll('.hs-error-msg');
								if (errorMessages.length > 0) {
									form.classList.remove('hidden');
									observer.disconnect(); // Stop observing once we've found errors
								}
							}
						});
					});

					// Start observing the form for changes
					observer.observe(form, {
						childList: true,
						subtree: true
					});

					const submitButton = form.querySelector('input[type="submit"]');
					if (submitButton) {
						submitButton.click();
					} else {
						console.error('Submit button not found in .form--hubspot');
					}
				} else {
					console.error('.form--hubspot form not found');
				}
			}
		} catch (error) {
			console.error('Error processing message:', error);
		}
	}
});
