/*###########################################################################
// Parallax
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

gsap.registerPlugin(ScrollTrigger)

const parallax = {
  config: {
    parallaxImage: '.parallaxImage'
  },

  elements: {},

  init() {
    const self = this

    self.elements.parallaxImages = document.querySelectorAll(self.config.parallaxImage)
    if (self.elements.parallaxImages !== null) {
      for (let i = 0; i < self.elements.parallaxImages.length; i++) {
        const parallaxImage = self.elements.parallaxImages[i]

        self.handleParallaxImage(parallaxImage)
      }
    }

		// Ensure that when accordions/expandables on the page open/close, that pinning positions are updated.
		//
		window.addEventListener('accordion-open', function() {
			setTimeout(function() {
				ScrollTrigger.sort()
				ScrollTrigger.refresh()
			}, 500)
		})
  },

  handleParallaxImage(parallaxImage) {
    const self = this

    const image = parallaxImage.querySelector("img")
    const imageMobile = parallaxImage.querySelector(".parallaxImage__mobile-img")

    let parallaxImageY = (image) => {
      if (imageMobile !== null && window.innerWidth >= 640) {
        return image.offsetHeight - parallaxImage.offsetHeight
      } else if (imageMobile === null) {
        return image.offsetHeight - parallaxImage.offsetHeight
      }
    }

    if (parallaxImage.classList.contains('parallaxImage--reverse') && window.innerWidth >= 640) {
      parallaxImageY = (image) => {
        if (imageMobile !== null && window.innerWidth >= 640) {
          return -(image.offsetHeight - parallaxImage.offsetHeight * 1.5)
        } else if (imageMobile === null) {
          return -(image.offsetHeight - parallaxImage.offsetHeight * 1.5)
        }
      }
    }

    gsap.to(image, {
      y: () => parallaxImageY(image),
      ease: "power1.out",
      scrollTrigger: {
        trigger: parallaxImage,
        scrub: true,
        pin: false
      },
    })

    if (imageMobile !== null) {
      let parallaxImageMobileY = (image) => {
        if (window.innerWidth < 640) {
          return imageMobile.offsetHeight - parallaxImage.offsetHeight
        }
      }

      gsap.to(imageMobile, {
        y: () => parallaxImageMobileY(imageMobile),
        ease: "power1.out",
        scrollTrigger: {
          trigger: parallaxImage,
          scrub: true,
          pin: false
        },
      })
    }
  }
}

window.addEventListener('page-change', function() {
	setTimeout(function() {
    parallax.init()
  }, 500)
})

window.addEventListener('load', function () {
  setTimeout(function() {
    parallax.init()
  }, 500)
})
