/*###########################################################################
// Slider entry
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import '../vendor/gsap/_blurPlugin'

gsap.registerPlugin(ScrollTrigger)

const sliderEntry = {
  config: {
    sliderEntry: '.sliderEntry'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderEntryEls = document.querySelectorAll(self.config.sliderEntry)

    if (self.elements.sliderEntryEls !== null) {
			for (let i = 0; i < self.elements.sliderEntryEls.length; i++) {
				const sliderEntryEl = self.elements.sliderEntryEls[i]

				self.handleSliderEntry(sliderEntryEl)
			}
    }
  },

  handleSliderEntry(sliderEntryEl) {
    const self = this

		const speed = sliderEntryEl.hasAttribute('data-speed') ? parseInt(sliderEntryEl.getAttribute('data-speed')) : 3
    const swiperSlides = sliderEntryEl.querySelectorAll('.swiper-slide')

    for (let i = 0; i < swiperSlides.length; i++) {
      const swiperSlide = swiperSlides[i]

      gsap.set(swiperSlide, { x: (window.innerWidth * 1.5), blur: 16 })
      gsap.to(swiperSlide, {
				duration: speed,
        x: "0px",
				blur: 0,
        ease: "power2.out",
        scrollTrigger: {
          trigger: sliderEntryEl,
          start: "top +=" + (window.innerHeight / 1.25) + " middle",
					onEnter: function() {
						setTimeout(function() {
							sliderEntryEl.classList.add('after-entry')
						}, 2000)
					},
          onLeave: self => self.kill(false, true)
        }
      })
    }

		// Optional soft fade in for slider controls
		//
		if (sliderEntryEl.hasAttribute('data-controls')) {
			const controls = document.querySelector(sliderEntryEl.getAttribute('data-controls'))

			if (controls !== null) {
				gsap.set(controls, { alpha: 0 })
				gsap.to(controls, {
					duration: speed,
					alpha: 1,
					ease: "power2.out",
					scrollTrigger: {
						trigger: sliderEntryEl,
						start: "top +=" + (window.innerHeight / 1.25) + " middle",
						onLeave: self => self.kill(false, true)
					}
				})
			}
		}
  }
}

window.addEventListener('page-change', function () {
  setTimeout(function() {
    sliderEntry.init()
  }, 250)
})


window.addEventListener('load', function () {
  setTimeout(function() {
    sliderEntry.init()
  }, 250)
})
