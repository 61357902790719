import { debounce } from "../utilities/_throttleDebounce"

const sticksy = {
	init() {
		const sticksyElements = document.querySelectorAll('.sticksy')

		for (let i = 0; i < sticksyElements.length; i++) {
			const sticksyInstance = new Sticksy(sticksyElements[i], {
				topSpacing: sticksyElements[i].hasAttribute('data-top-spacing') ? parseInt(sticksyElements[i].getAttribute('data-top-spacing')) : 120
			});

			setTimeout(function() {
				window.addEventListener('img-loaded', function() {
					sticksyInstance.hardRefresh()
				})
			}, 1000)

			setTimeout(function() {
				sticksyInstance.hardRefresh()
			}, 5000)

			if (sticksyElements[i].classList.contains('sticksy--md-up')) {
				if (window.innerWidth < 768) {
					sticksyInstance.disable()
					sticksyInstance.hardRefresh()
				}

				window.addEventListener('resize', debounce(function () {
					if (window.innerWidth < 768) {
						sticksyInstance.disable()
						sticksyInstance.hardRefresh()
					} else {
						sticksyInstance.enable()
						sticksyInstance.hardRefresh()
					}
				}, 250))
			}
		}
	}
}

window.addEventListener('load', function() {
	setTimeout(function() {
		sticksy.init()
	}, 500)
})

window.addEventListener('page-change', function() {
	setTimeout(function() {
		sticksy.init()
	}, 500)
})
