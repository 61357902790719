/*###########################################################################
// Stacking elements
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import '../vendor/gsap/_blurPlugin'
import lottie from 'lottie-web-light'

gsap.registerPlugin(ScrollTrigger)

const stackingElements = {
  config: {
		sectionsContainer: '.sections',
		section: '.section',
		gridLines: '.gridLines',
		img: '[section-img]',
		lottie: '[section-lottie]',
		text: '[section-text]',
		btn: '[section-btn]',
		heading: '[section-heading]'
  },

  elements: {},

  init() {
    const self = this

		const sectionsContainer = document.querySelectorAll(self.config.sectionsContainer)

		if (window.innerWidth >= 1024) {
			sectionsContainer.forEach((sectionsContainer) => {
				self.handleStackingInstance(sectionsContainer)
			})
		}
  },

	handleStackingInstance(sectionsContainer) {
		const self = this

		const sections = sectionsContainer.querySelectorAll(self.config.section)

		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: sectionsContainer,
				start: "top top",
				end: "+=" + sectionsContainer.clientHeight * 4,
				pin: true,
				pinSpacing: true,
				scrub: true
			}
		})

		for (let i = 0; i < sections.length; i++) {
			const section = sections[i]

			const gridLines = section.querySelector(self.config.gridLines)
			const heading = section.querySelector(self.config.heading)
			const text = section.querySelector(self.config.text)
			const btn = section.querySelector(self.config.btn)
			const img = section.querySelector(self.config.img)
			const lottieEl = section.querySelector(self.config.lottie)

			if (i !== 0) {
				tl.from(section, {
					duration: 2.5,
					y: window.innerHeight,
					ease: "power1.out"
				}, "+=1")

				if (gridLines != null) {
					let lottieAnimation = null
					let totalFrames = 0

					if (lottieEl !== null) {
						lottieAnimation = lottie.loadAnimation({
							container: lottieEl,
							renderer: 'svg',
							loop: false,
							autoplay: false,
							path: lottieEl.getAttribute('data-lottie')
						})

						lottieAnimation.addEventListener('DOMLoaded', () => {
							totalFrames = lottieAnimation.totalFrames
						});
					}

					tl.from(gridLines.querySelector('span:nth-child(1)'), {
						duration: 1,
						x: -window.innerWidth * 1.25,
						ease: "power1.out"
					}, "-=1")

					tl.from(gridLines.querySelector('span:nth-child(3)'), {
						duration: 1,
						alpha: 0,
						x: -window.innerHeight / 3,
						ease: "power1.out"
					}, "-=1")

					tl.from(gridLines.querySelector('span:nth-child(4)'), {
						duration: 1,
						alpha: 0,
						y: -window.innerHeight/5,
						ease: "power1.out"
					}, "-=1")

					tl.from(heading, {
						duration: 1,
						blur: 15,
						alpha: 0,
						y: 40,
						ease: "power1.out"
					}, "-=1")

					tl.from(text, {
						duration: 1,
						alpha: 0,
						y: 20,
						ease: "power1.out"
					}, "-=1")

					tl.from(btn, {
						duration: 1,
						alpha: 0,
						y: 20,
						ease: "power1.out"
					}, "-=1")

					if (lottieEl !== null) {
						tl.from(img, {
							duration: 1,
							alpha: 0,
							scale: 1.05,
							ease: "power1.out"
						}, "-=1")

						tl.from(lottieEl, {
							duration: 2,
							ease: "power1.out",
							onUpdate: function() {
								const frame = Math.floor(this.progress() * totalFrames)
								lottieAnimation.goToAndStop(frame, true)
							},
						}, "-=1")
					} else {
						tl.from(img, {
							duration: 1,
							alpha: 0,
							scale: 1.05,
							ease: "power1.out"
						}, "-=1")
					}
				}
			}
		}

		setTimeout(function() {
			ScrollTrigger.refresh()
			ScrollTrigger.update()
		}, 1000)
	}
}

window.addEventListener('page-change', function() {
	setTimeout(function() {
    stackingElements.init()
  }, 500)
})

window.addEventListener('load', function () {
  setTimeout(function() {
    stackingElements.init()
  }, 500)
})
