/*###########################################################################
// Case Studies Filter
//#########################################################################*/

import { scrollTo } from '../utilities/_scrollTo'

const caseStudiesFilter = {
	config: {
		pillBtn: '.pillBtn',
		filterReset: '#filterReset'
	},

	elements: {},

	init() {
		const self = this

	 	if (document.querySelector('#entry-container') !== null && document.querySelector('#entry-container').getAttribute('data-case-studies') == 'true') {
			self.elements.pillBtns = document.querySelectorAll(self.config.pillBtn)

			self.handlePillBtns()

			if (typeof htmx !== 'undefined') {
				// Add loading state
				//
				htmx.on('htmx:beforeRequest', function (event) {
					document.querySelector('#entry-container').classList.add('is-loading')
				})

				// After swap re-add event listeners for the above and remove loading state
				//
				htmx.on('htmx:afterSwap', function (event) {
					document.querySelector('#entry-container').classList.remove('is-loading')
				})
			}

			self.elements.filterReset = document.querySelector(self.config.filterReset)
			self.handleReset()
	 	}
	},

	handlePillBtns() {
		const self = this

		if (self.elements.pillBtns !== null) {
			for (let i = 0; i < self.elements.pillBtns.length; i++) {
				const pillBtn = self.elements.pillBtns[i]
				const selectToSync = document.querySelector(pillBtn.getAttribute('data-select'))

				pillBtn.addEventListener('click', function(e) {
					e.preventDefault()

					// Reset buttons in group
					//
					const pillBtnsInGroup = document.querySelectorAll('[data-group="' + pillBtn.getAttribute('data-group') + '"]')
					for (let i = 0; i < pillBtnsInGroup.length; i++) {
						const pillBtn = pillBtnsInGroup[i]

						pillBtn.classList.remove('pillBtn--light')
					}

					// Set current button
					pillBtn.classList.add('pillBtn--light')
					selectToSync.value = pillBtn.getAttribute('data-value')

					// Update filter button
					const filterBtn = document.querySelector(pillBtn.getAttribute('data-filter-btn'))
					if (filterBtn !== null) {
						filterBtn.querySelector('.filterBtn__text').innerText = pillBtn.innerText

						const targetTab = document.querySelector(filterBtn.getAttribute('data-target'))

						// Close current target tab
						//
						targetTab.classList.remove('is-active')
						filterBtn.classList.remove('is-active')
					}

					// Show reset button if filter selected
					if (self.elements.filterReset.classList.contains('hidden')) {
						self.elements.filterReset.classList.remove('hidden')
					}

					// Trigger a change event for sprig so it updates
					let changeEvent = new Event('change')
					selectToSync.dispatchEvent(changeEvent)

					// Scroll to entries
					self.scrollToContainer()
				})
			}
		}
	},

	handleReset() {
		const self = this

		self.elements.filterReset.addEventListener('click', function() {
			self.elements.filterReset.classList.add('hidden')

			document.querySelector('#filterBtnSector').querySelector('.filterBtn__text').innerText = document.querySelector('#filterBtnSector').getAttribute('data-default-text')

			// Reset all pill buttons
			//
			for (let i = 0; i < self.elements.pillBtns.length; i++) {
				self.elements.pillBtns[i].classList.remove('pillBtn--light')
			}

			// Reset select values
			document.querySelector('#sectorId').value = ""

			// Trigger one* change event so sprig updates properly
			let changeEvent = new Event('change')
			document.querySelector('#sectorId').dispatchEvent(changeEvent)
		})
	},

	scrollToContainer() {
    const offsetAmount = document.querySelector('.header').clientHeight + (window.innerWidth < 1024 ? 140 : 80)
    scrollTo(document.querySelector('#entry-container').parentNode, -offsetAmount)
  }
}

window.addEventListener('page-change', function() {
	caseStudiesFilter.init()
})

window.addEventListener('load', () => {
	caseStudiesFilter.init()
})
