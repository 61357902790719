import Immerser from '../immerser/main'
import { debounce } from '../utilities/_throttleDebounce'

window.addEventListener('load', function() {
	let immerserInstance = null

	setTimeout(function() {
		immerserInstance = new Immerser({
			hasToUpdateHash: false,
			scrollAdjustThreshold: 0,
			scrollAdjustDelay: 300
		});

		// Ensure scroll points are accurately updated, even after GSAP pinned sections are initialised
		//
		immerserInstance.onDOMChange()

		setTimeout(function() {
			immerserInstance.onDOMChange()

			window.addEventListener('resize', debounce(function () {
				setTimeout(function() {
					immerserInstance.onDOMChange()
				}, 100)
      }, 250))

			window.addEventListener('img-loaded', function() {
				immerserInstance.onDOMChange()
			})

			window.addEventListener('accordion-open', function() {
				setTimeout(function() {
					immerserInstance.onDOMChange()
				}, 750)
			})

			window.addEventListener('hs-loaded', function() {
				setTimeout(function() {
					immerserInstance.onDOMChange()
				}, 750)
			})

			// Handle Sprig/HTMX if it exists on the page
			//
			if (typeof htmx !== 'undefined') {
				htmx.on('htmx:afterSwap', function (event) {
					setTimeout(function() {
						immerserInstance.onDOMChange()
					}, 1000)
				})
			}
		}, 500)

		setTimeout(function() {
			immerserInstance.onDOMChange()
		}, 5000)
	}, 500)

	window.addEventListener('page-change', function() {
		immerserInstance.destroy()

		setTimeout(function() {
			setTimeout(function() {
				immerserInstance = null

				immerserInstance = new Immerser({
					hasToUpdateHash: false,
					scrollAdjustThreshold: 0,
					scrollAdjustDelay: 300
				});

				// Ensure scroll points are accurately updated, even after GSAP pinned sections are initialised
				//
				immerserInstance.onDOMChange()

				setTimeout(function() {
					immerserInstance.onDOMChange()

					window.addEventListener('img-loaded', function() {
						immerserInstance.onDOMChange()
					})

					window.addEventListener('accordion-open', function() {
						setTimeout(function() {
							immerserInstance.onDOMChange()
						}, 750)
					})

					window.addEventListener('hs-loaded', function() {
						setTimeout(function() {
							immerserInstance.onDOMChange()
						}, 750)
					})

					// Handle Sprig/HTMX if it exists on the page
					//
					if (typeof htmx !== 'undefined') {
						htmx.on('htmx:afterSwap', function (event) {
							setTimeout(function() {
								immerserInstance.onDOMChange()
							}, 1000)
						})
					}
				}, 500)
			}, 100)
		}, 250)

		setTimeout(function() {
			immerserInstance.onDOMChange()
		}, 5000)
	})
})
