/*###########################################################################
// Slider gallery
//#########################################################################*/

import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const sliderGallery = {
  config: {
    sliderGallery: '.sliderGallery'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderGalleries = document.querySelectorAll(self.config.sliderGallery)

    if (self.elements.sliderGalleries !== null) {
      for (let i = 0; i < self.elements.sliderGalleries.length; i++) {
        const sliderGallery = self.elements.sliderGalleries[i]

        self.handleSliderGallery(sliderGallery)
      }
    }
  },

  handleSliderGallery(slider) {
    const self = this

    const swiper = new Swiper(slider, {
			modules: [Autoplay],
			speed: 3000,
			autoplay: {
        delay: 0,
      },
      slidesPerView: 2.4,
      spaceBetween: 16,
      loop: true,
      autoHeight: false,
			allowTouchMove: false,
			breakpoints: {
				480: {
					spaceBetween: 20,
					slidesPerView: 3.2,
				},
				1024: {
					spaceBetween: 32,
					slidesPerView: 4,
				}
			}
    })

		// Pause autoplay on init
		//
		swiper.autoplay.stop()

		// Handle autoplay start when scroll to cards and sliderEntry triggered
		//
		ScrollTrigger.create({
			trigger: slider.parentNode,
			start: "top +=" + (window.innerHeight / 1.25) + " middle",
			onEnter: (self) => {
				swiper.autoplay.start()
			},
			onUpdate: (self) => {
				swiper.autoplay.start()
			},
			onLeave: (self) => {
				self.kill(false, true)
			},
		})
  }
}

window.addEventListener('page-change', function() {
	setTimeout(function() {
    sliderGallery.init()
  }, 500)
})

window.addEventListener('load', function () {
  sliderGallery.init()
})
