/*###########################################################################
// Proximity Parallax
//#########################################################################*/

import { gsap } from "gsap"

const proxPara = {
	config: {
		proxPara: '[data-proximity-parallax]'
	},

	elements: {},

	init() {
		const self = this

		self.elements.proxParas = document.querySelectorAll(self.config.proxPara)

		if (self.elements.proxParas !== null) {
			for (let i = 0; i < self.elements.proxParas.length; i++) {
				const proxPara = self.elements.proxParas[i]

				self.handleProxPara(proxPara)
			}
		}
	},

	handleProxPara(proxPara) {
		const self = this

		// Get bounding client rect
		//
		let rect = proxPara.getBoundingClientRect()

		// Initialize mouse object with initial values
		//
		let mouse = {
			x: 0,
			y: 0,
			moved: false,
			leave: false
		}

		// Add mousemove event listener to the container
		//
		proxPara.addEventListener('mousemove', function(e) {
			mouse.moved = true
			mouse.leave = false
			mouse.x = e.clientX - rect.left
			mouse.y = e.clientY - rect.top
		})

		// Handle mouse leave
		//
		proxPara.addEventListener('mouseleave', function(e) {
			mouse.moved = true
			mouse.leave = true
		})

		// Ticker event will be called on every frame
		gsap.ticker.add(function() {
			if (window.innerWidth >= 1024) {
				if (mouse.moved) {
					const parallaxEl = proxPara.querySelectorAll('[data-proximity-parallax-item]')

					parallaxEl.forEach((element) => {
						const paraValue = mouse.leave ? 0 : element.dataset.speed
						const easing = "power2"
						const speed = mouse.leave ? 1 : 0.5
						self.parallaxIt(element, paraValue, speed, easing, mouse, rect)
					})
				}

				mouse.moved = false
			}
		})

		// Add resize and scroll event listeners to the window
		//
		window.addEventListener('resize', updateRect);
		window.addEventListener('scroll', updateRect);

		// Function to update the container's bounding rectangle on resize and scroll
		//
		function updateRect() {
			rect = proxPara.getBoundingClientRect();
		}
	},

	parallaxIt(target, movement, speed, easing, mouse, rect) {
    gsap.to(target, {
			duration: speed,
			ease: easing,
			x: (mouse.x - rect.width / 2) / rect.width * movement,
			y: (mouse.y - rect.height / 2) / rect.height * movement
    });
	}
}

window.addEventListener('page-change', function () {
  proxPara.init()
})

window.addEventListener('load', function () {
	proxPara.init()
})
