/*###########################################################################
// Slider full width
//#########################################################################*/

import Swiper from 'swiper'
import { Autoplay } from 'swiper/modules'
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const sliderFullWidth = {
  config: {
    sliderFullWidth: '.sliderFullWidth'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderFullWidth = document.querySelectorAll(self.config.sliderFullWidth)

    if (self.elements.sliderFullWidth !== null) {
      for (let i = 0; i < self.elements.sliderFullWidth.length; i++) {
        const sliderFullWidth = self.elements.sliderFullWidth[i]

        self.handleSliderFullWidth(sliderFullWidth)
      }
    }
  },

  handleSliderFullWidth(slider) {
    const self = this

    const swiper = new Swiper(slider, {
			modules: [Autoplay],
			speed: window.innerWidth < 1024 ? 1000 : 1500,
			autoplay: {
        delay: 2000,
      },
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
      autoHeight: false,
			allowTouchMove: true
    })

		// Pause autoplay on init
		//
		swiper.autoplay.stop()

		let isPlaying = false

		// Handle autoplay start when scrolling to section
		//
		ScrollTrigger.create({
			trigger: slider.parentNode,
			start: "top +=" + (window.innerHeight / 1.25) + " middle",
			onEnter: (self) => {
				if (isPlaying === false) {
					swiper.autoplay.start()

					isPlaying = true
				}
			},
			onUpdate: (self) => {
				if (isPlaying === false) {
					swiper.autoplay.start()

					isPlaying = true
				}
			},
			onLeave: (self) => {
				self.kill(false, true)
			},
		})
  }
}

window.addEventListener('page-change', function() {
	setTimeout(function() {
    sliderFullWidth.init()
  }, 500)
})

window.addEventListener('load', function () {
  sliderFullWidth.init()
})
