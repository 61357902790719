/*###########################################################################
// Curve
//#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const curve = {
	config: {
		curve: '.curve'
	},

	elements: {},

	init() {
		const self = this

		self.elements.curves = document.querySelectorAll(self.config.curve)

		if (self.elements.curves !== null) {
			for (let i = 0; i < self.elements.curves.length; i++) {
				const curve = self.elements.curves[i]

				self.handleCurve(curve)
			}

			setTimeout(function() {
				ScrollTrigger.sort()
				ScrollTrigger.refresh()
			}, 1000)
		}
	},

	handleCurve(curve) {
		const self = this

		const curveTl = gsap.timeline({
			scrollTrigger: {
				trigger: curve,
				start: "top bottom",
				end: "top center",
				scrub: true
			}
		})

		curveTl.to(curve.querySelector('path'), {
			duration: 0.8,
			ease: 'none',
			attr: { d: curve.querySelector('path').getAttribute('data-to') }
		})
	}
}

window.addEventListener('page-change', function () {
  curve.init()
})

window.addEventListener('load', function () {
	curve.init()
})
