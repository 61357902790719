/*###########################################################################
// Slider content
//#########################################################################*/

import Swiper from 'swiper'
import { throttle } from '../utilities/_throttleDebounce'

const sliderContent = {
  config: {
    sliderContent: '.sliderContent'
  },

  elements: {},

  init() {
    const self = this

    self.elements.sliderContent = document.querySelectorAll(self.config.sliderContent)

    if (self.elements.sliderContent !== null) {
      for (let i = 0; i < self.elements.sliderContent.length; i++) {
        const sliderContent = self.elements.sliderContent[i]

        self.handleSliderContent(sliderContent)
      }
    }
  },

  handleSliderContent(slider) {
    const self = this

		const controls = slider.parentNode.parentNode.querySelector('.slider__controls')
		const prevBtn = slider.parentNode.parentNode.querySelector('.slider__control--prev')
    const nextBtn = slider.parentNode.parentNode.querySelector('.slider__control--next')

    const swiper = new Swiper(slider, {
			speed: 1000,
      slidesPerView: 1.35,
			watchSlidesProgress: true,
      spaceBetween: 32,
      loop: false,
			autoHeight: true,
			allowTouchMove: true,
			breakpoints: {
				640: {
					slidesPerView: 2.4,
				},
				1024: {
					slidesPerView: 3,
				}
			}
    })

		setTimeout(() => {
			self.controlsVisibleCheck(swiper, controls)

			window.addEventListener('resize', throttle(function () {
				self.controlsVisibleCheck(swiper, controls)
			}, 250))
		}, 500)

		if (swiper.isBeginning) {
      prevBtn.classList.add('is-disabled')
    }

    swiper.on('slideChange', function() {
      if (swiper.isBeginning) {
        prevBtn.classList.add('is-disabled')
      }

      if (swiper.isEnd) {
        nextBtn.classList.add('is-disabled')
      }
    })

    swiper.on('fromEdge', function() {
      prevBtn.classList.remove('is-disabled')
      nextBtn.classList.remove('is-disabled')
    })

    swiper.on('reachBeginning', function () {
      if (swiper.isBeginning) {
        prevBtn.classList.add('is-disabled')
      }
    })

    swiper.on('reachEnd', function () {
      if (swiper.isEnd) {
        nextBtn.classList.add('is-disabled')
      }
    })

    prevBtn.addEventListener('click', function (e) {
      swiper.slidePrev()
    })

    nextBtn.addEventListener('click', function (e) {
      swiper.slideNext()
    })
  },

	controlsVisibleCheck(swiper, controls) {
    const totalSlides = swiper.slides.length
    const slidesPerView = swiper.params.slidesPerView

    if (totalSlides <= slidesPerView) {
      controls.classList.add('slider__controls--hidden')
      swiper.disable()
    } else {
      controls.classList.remove('slider__controls--hidden')
      swiper.enable()
    }
  },
}

window.addEventListener('page-change', function() {
	setTimeout(function() {
    sliderContent.init()
  }, 500)
})

window.addEventListener('load', function () {
  sliderContent.init()
})
