/*###########################################################################
Project list
#########################################################################*/

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { throttle } from '../utilities/_throttleDebounce'

const projectsList = {
  config: {
		projectsList: '[projects-list]',
    bgImage: '[projects-img]',
		projectItem: '[project-item]',
  },

  elements: {},

  init() {
    const self = this
		self.elements.projectsList = document.querySelector(self.config.projectsList)

		if (self.elements.projectsList !== null) {
			const projectItems = self.elements.projectsList.querySelectorAll(self.config.projectItem)
			const bgImages = self.elements.projectsList.querySelectorAll(self.config.bgImage)

			self.handleProjectsList(projectItems, bgImages)
		}
  },

  handleProjectsList(projectItems, bgImages) {
    const self = this

		for (let i = 0; i < projectItems.length; i++) {
			const projectItem = projectItems[i]
			const bgImage = bgImages[i]
			const arrowIcon = projectItem.querySelector('.arrow-icon')

			let mm = gsap.matchMedia()
			let tl = null

			mm.add("(min-width: 1024px)", () => {
				gsap.set(bgImage, { scale: 1.1, autoAlpha: 0 })

				tl = gsap.timeline({
					paused: true
				})

				tl.to(bgImage, {
					scale: 1,
					autoAlpha: 1,
					duration: 0.5
				})

				return () => {
					gsap.set(projectItem, { autoAlpha: 0.5 })
				}
			})

			projectItem.addEventListener('mouseenter', () => {
				if (window.innerWidth >= 1024) {
					tl.play()
					for (let i = 0; i < projectItems.length; i++) {
						const currentProjectItem = projectItems[i]
						if (!projectItem.isSameNode(currentProjectItem)) {
							gsap.to(currentProjectItem.querySelector('div:first-child'), {
								duration: 0.5,
								alpha: 0.5
							})
						}
					}
				}
			})

			projectItem.addEventListener('mouseleave', () => {
				if (window.innerWidth >= 1024) {
					tl.reverse()
					for (let i = 0; i < projectItems.length; i++) {
						const currentProjectItem = projectItems[i]

						if (!projectItem.isSameNode(currentProjectItem)) {
							gsap.to(currentProjectItem.querySelector('div:first-child'), {
								duration: 0.5,
								alpha: 1
							})
						}
					}
				}
			})

			mm.add("(max-width: 1023px)", () => {
				const tlMobileBg = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: projectItem,
						start: 'top center',
						end: 'bottom center',
						toggleActions: i === projectItems.length-1 ? "play none play reverse" : "play reverse play reverse",
					}
				})

				if (i === 0) {
					gsap.set(bgImage, {
						scale: 1,
						autoAlpha: 1
					})
				} else {
					tlMobileBg.to(bgImage, {
						scale: 1,
						autoAlpha: 1,
						duration: 0.25
					}, '-=0.5')
				}

				const tlMobile = gsap.timeline({
					paused: true,
					scrollTrigger: {
						trigger: projectItem,
						start: 'top center',
						end: 'bottom center',
						toggleActions: "play reverse play reverse",
					}
				})

				tlMobile.to(projectItem, {
					autoAlpha: 1,
					duration: 0.5
				})

				tlMobile.to(arrowIcon, {
					autoAlpha: 1,
					duration: 0.5
				}, '-=0.5')

				return () => {
					gsap.set(projectItem, { autoAlpha: 1 })
				}
			})
		}

		setTimeout(() => {
			const onScroll = throttle(() => {
				if (self.elements.projectsList !== null) {
					if (ScrollTrigger.isInViewport(self.elements.projectsList)) {
						ScrollTrigger.sort()
						ScrollTrigger.refresh()
						window.removeEventListener('scroll', onScroll)
					}
				}
			}, 200)

			window.addEventListener('scroll', onScroll)
		}, 500)
	}
}

window.addEventListener('page-change', function() {
	setTimeout(function() {
    projectsList.init()
  }, 500)
})

window.addEventListener('load', function () {
  setTimeout(function() {
    projectsList.init()
  }, 500)
})
